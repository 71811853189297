import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/Layout"
import Footer from "../components/footer/Footer"
import { Fade } from "react-awesome-reveal"
import { Link } from "gatsby"

const Article = ({ data }) => {
  const article = data.article

  return (
    <Layout
      title={article.metaTitle || article.title}
      description={article.metaDescription}
    >
      <div className="grid lg:gap-x-gs lg:grid-cols-8 bg-teal py-8 xl:py-32 px-ogs">
        {article.image.length > 0 && article.image[0].localFile && (
          <div className="mt-8 col-span-3">
            <GatsbyImage
              image={getImage(article.image[0].localFile)}
              alt={article.image[0].title}
            />
          </div>
        )}
        <div className="text-warm-yellow lg:col-start-4 lg:col-span-full">
          <h2 className="text-6xl xl:text-6xlb font-fletcha tracking-tightest leading-16 mt-8">
            {article.title}
          </h2>{" "}
          <p className="italic">
            Posted on:{" "}
            {new Date(article.postDate).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "long",
              year: "numeric",
            })}
          </p>
          <div
            className="font-tomato text-sm pb-16 mt-12 rte"
            dangerouslySetInnerHTML={{ __html: article.body }}
          ></div>{" "}
          <Fade triggerOnce duration={500} className="py-6">
            <Link
              to="/blog"
              className="Link text-warm-yellow Link--alt Link--underline text-xl lg:text-3xl font-tomato tracking-tightest cursor-pointer"
            >
              Back to blog
            </Link>
          </Fade>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default Article

export const articleQuery = graphql`
  query ArticleQuery($uri: String) {
    article: craftArticlesArticlesEntry(uri: { eq: $uri }) {
      title
      body
      postDate
      uri
      metaTitle
      metaDescription
      image {
        ... on Craft_images_Asset {
          id
          title
          localFile {
            childImageSharp {
              gatsbyImageData(
                aspectRatio: 1
                transformOptions: { fit: COVER, cropFocus: CENTER }
              )
            }
          }
        }
      }
    }
  }
`
